import React from "react";
import { Link } from "react-router-dom";
import shapes from "../../../assets/img/shapes.png";
import SectionTitle from "../../../components/SectionTitle";
import img1 from "../img/blog-1.png";
import img2 from "../img/blog-2.png";
import img3 from "../img/blog-3.png";
import img4 from "../img/blog-4.png";
import img5 from "../img/blog-5.png";
import img6 from "../img/blog-6.png";
import img7 from "../img/blog-7.png";

const data = [
	{
		id: "1",
		img: img1,
		title: "Virtual Kitchen VS. Ghost Kitchen",
		subtext:
			"The food delivery industry has undergone significant changes in recent years, and these changes have had a significant impact on the restaurant industry.",
		text: [
			"One of the most significant changes in the food delivery industry is the rise of third-party delivery platforms, such as DoorDash, Grubhub, and UberEats. These platforms allow restaurants to easily reach a wider audience by connecting them with customers who are looking for food delivery. This has helped many restaurants, particularly small and independent ones, to increase their sales and revenue by offering delivery options to...",
		],
	},
	{
		id: "2",
		img: img2,
		title: "The Future of Dining: Why Every Restaurant Needs a Virtual Kitchen",
		subtext:
			"The restaurant industry is evolving rapidly, with delivery services becoming an essential revenue stream for many establishments. One innovative solution that is revolutionizing the way restaurants operate is the virtual kitchen.",
		listSubText: "By adopting a virtual kitchen model, restaurants can increase their profit margins without the need for additional space or staff. Simple Virtual Kitchen offers a turnkey solution that allows restaurant owners to implement virtual brands within their existing kitchens, maximizing efficiency and boosting sales. In this guide, we'll explore why every restaurant needs a virtual kitchen, and how this model can transform your business into a more profitable and resilient operation",
		list: [
			
		],
	},
	{
		id: "3",
		img: img3,
		title: "Unlock Hidden Revenue: How Simple Virtual Kitchen Can Boost Your Restaurant's Profits by 40%",
		subtext:
			"In restaurant industry, finding innovative ways to boost profits without increasing operational costs is crucial. Enter the concept of the Simple Virtual Kitchen—a turnkey solution designed to generate additional revenue through a delivery-only model.",
		text: [
			"By partnering with Simple Virtual Kitchen, restaurant owners can leverage their existing kitchen space, staff, and rent to implement virtual brands that drive sales and elevate profitability by at least 40% per month. This guide will delve into how a virtual kitchen can seamlessly integrate into your current operations, highlight the benefits of virtual brands, and provide actionable steps to unlock hidden revenue streams, ensuring your restaurant thrives in the evolving market.",
		],
	},
	{
		id: "4",
		img: img4,
		title: "Maximize Your Kitchen's Potential: A Guide to Implementing Virtual Brands",
		subtext:
			"In today's competitive restaurant landscape, finding innovative ways to boost revenue without escalating operational costs is crucial. Virtual kitchens, also known as cloud kitchens, offer a compelling solution that can help restaurants maximize their existing resources.",
		text: [
			"By partnering with Simple Virtual Kitchen, restaurant owners can implement virtual brands that thrive solely on delivery platforms, leveraging their current kitchen, rent, and staff. This strategic approach not only optimizes kitchen space but also ensures a significant increase in sales, potentially generating a net profit uplift of at least 40% per month. In this guide, we'll delve into the benefits and implementation of virtual brands to help you enhance your restaurant's profitability and efficiency.",
		],
	},
	{
		id: "5",
		img: img5,
		title: "Transform Your Restaurant with Simple Virtual Kitchen: The Ultimate Virtual Kitchen Solution",
		subtext:
			"In a world where dining trends are rapidly shifting towards delivery and takeout, finding new revenue streams without incurring hefty operational costs is crucial for restaurant owners. ",
		text: [
			"Enter Simple Virtual Kitchen, a turnkey solution designed to transform your establishment into a thriving virtual kitchen with ease. By leveraging your existing kitchen space, rent, and staff, Simple Virtual Kitchen introduces virtual brands that significantly boost sales and profit margins. This innovative cloud kitchen model ensures that restaurants can tap into the growing delivery market, generating a net profit increase of at least 40% per month. Discover how this virtual kitchen startup can revolutionize your business, optimize your operations, and enhance your profitability.",
		],
	},
	{
		id: "6",
		img: img6,
		title: "From Concept to Profit: How Virtual Kitchens Are Revolutionizing the Restaurant Industry",
		subtext:
			"In the rapidly evolving restaurant industry, virtual kitchens are emerging as a game-changer, offering a revolutionary approach to increasing revenue without the burden of additional operational costs",
		text: [
			"Simple Virtual Kitchen provides an innovative solution that allows restaurant owners and managers to leverage their existing kitchens to run delivery-only virtual brands. By optimizing kitchen space and utilizing current staff and resources, restaurants can tap into new revenue streams with minimal upfront investment. This model not only helps cover expenses but can also generate a net profit increase of at least 40% per month. In this document, we'll delve into how virtual kitchens are transforming the industry, the benefits they offer, and why they might be the perfect solution for your restaurant.",
		],
	},
	{
		id: "7",
		img: img7,
		title: "How Virtual Brands Can Transform Your Existing Kitchen Space into a Profit Powerhouse",
		subtext:
			"Innovative ways to boost revenue without escalating costs is crucial. Enter Simple Virtual Kitchen, a game-changing solution designed to transform your existing kitchen space into a profit powerhouse through virtual brands.",
		text: [
			"By leveraging a delivery-only model, Simple Virtual Kitchen allows you to maximize your kitchen's potential without the need for additional rent, staff, or operational expenses. This turnkey approach not only helps cover existing expenses but can also generate a net profit increase of at least 40% per month. Join us as we explore how embracing virtual kitchens can revolutionize your restaurant operations and elevate your profitability.",
		],
	},
];
const BlogCard = () => {
	return (
		<>
			<div className="blog-section section-gap">
				<div className="container">
					{data?.map(
						({ id, img, title, subtext, text, list, listSubText }, i) => (
							<div className="blog-card position-relative" key={i}>
								<img
									src={shapes}
									className="shape odd"
									style={{ top: "105px" }}
									alt=""
								/>
								<div
									className={`row gy-5 align-items-center position-relative ${
										i % 2 === 0 ? "flex-row-reverse" : ""
									}`}
								>
									<div className="col-lg-6 text-lg-end">
										<img
											src={img}
											alt=""
											className="mw-100"
											style={{ borderRadius: "10px" }}
										/>
									</div>
									<div className="col-lg-6">
										<div className="content">
											<SectionTitle
												shapeColor
												title={title ? title : ""}
												subtext={subtext ? subtext : ""}
											/>
											{text && (
												<div
													className="mt-29"
													style={{ maxWidth: "580px" }}
												>
													{text?.map((item, j) => (
														<p
															className={
																j + 1 === text.length
																	? "mb-0"
																	: ""
															}
														>
															{item}
														</p>
													))}
												</div>
											)}
											{list && (
												<div
													className="mt-29"
													style={{ maxWidth: "580px" }}
												>
													<div className="mb-1">{listSubText}</div>
													<ul className="ps-2">
														{list?.map((item, j) => (
															<li className="mb-1">
																{j + 1}. {item}
															</li>
														))}
													</ul>
												</div>
											)}
												<Link
											to={`/blog/${id}`}
												className="cmn-btn mt-37"
											>
												<span>READ MORE</span>
											</Link>
										</div>
									</div>
								</div>
							</div>
						)
					)}
				</div>
			</div>
		</>
	);
};

export default BlogCard;
