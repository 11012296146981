import React from "react";
import BookNow from "../../components/BookNow";
import SectionTitle from "../../components/SectionTitle";
import icon2 from "./img/bamboo-icon.png";
import img2 from "./img/bamboo.png";
import icon3 from "./img/bff-icon.png";
import img3 from "./img/bff.png";
import icon4 from "./img/chicken-icon.png";
import img4 from "./img/chicken.png";
import icon6 from "./img/pizza-icon.png";
import img6 from "./img/pizza.png";
import shapes from "./img/shapes.png";
import icon5 from "./img/subs-zone-icon.png";
import img5 from "./img/subszone.png";
import icon1 from "./img/wings-icon.png";
import img1 from "./img/wings.png";
import img7 from "./img/NYPD_Pasta.png";

const data = [
	{
		img: img1,
		title: "Just Chicken Wings",
		subtitle: "Chicken Wings & Boneless Wings.",
		text: [
			"Tasty & Crispy, our chicken wings and boneless wings are amazing!",
			"The culinary process to cook our chicken wings makes them delicious. Following our proper cooking techniques makes the meat tender and the skin crispy... our customers love it!",
			"Provided with six sauces from sweet to hot ones made by our culinary chef director: Buffalo, Barbecue, Honey Garlic, Lemon Pepper, Teriyaki and Cajun.",
		],
		icon: icon1,
		center: "true",
	},
	{
		img: img2,
		title: "The Imperial Bamboo",
		subtitle: "Chinese Food Style.",
		text: [
			"A combinaison of Chinese roots with an American taste: Flavorful and Yummy.",
			"Our recipes combine the traditional flavors and techniques of Chinese cooking with the wide variety of spices, ingredients, and flavor combinations found in America's melting pot. ",
			"The menu is composed of popular Chinese's dishes adapted to the American market: Noodles, fried rice, beef broccoli, chicken sesame and much more.",
		],
		icon: icon2,
	},
	{
		img: img3,
		title: "BFF",
		subtitle: "Burger & French Fries",
		text: [
			"Gourmet burgers and fries designed with French touch. ",
			"Burger buns we used combined with different type of cheeses and delicious sauce made by Karima, let a food print in our customers mouths that they will not about to forget.",
			"BFF’ menu is composed of 7 singular burger creations providing a unique culinary experience to our customers.",
		],
		icon: icon3,
	},
	{
		img: img4,
		title: "Crazy Tasty Chicken",
		subtitle: "Tenders, Thighs, and Breast Fried Chicken.",
		text: [
			"A virtual brand specially designed for gas stations and convenience stores.",
			"The flavor of our fried chicken is made using our own secret blend of herbs and spices that is provided. Chicken needs to be fried in a open or pressure fryer, which helps to seal in the juices and create a crispy exterior.",
			"Menu is composed of white and dark meat combos provided with biscuits.",
		],
		icon: icon4,
	},
	{
		img: img5,
		title: "Sub’s Zone",
		subtitle: "Delicious Cold and Hot Subs. ",
		text: [
			"Cold and toasted hoagies made with healthy and fresh ingredients.",
			"Quick and easy to prepare, our subs recipes are quick and simple to execute.  Customers choose among a variety of breads, meets, cheeses, toppings and condiments they want in order to create their hoagies. ",
			"They can either custom their own hoagies or choose our signature recipes, toast their bread or keep it fresh, that’s it. ",
		],
		icon: icon5,
		center: "true",
	},
	{
		img: img6,
		title: "NYPD Pizza",
		subtitle: "Authentic New York Style Pizza",
		text: [
			"NYPD Pizza recipes are inspired by famous New York pizza made with a hand-tossed, lightly-charred crust that is both crispy and chewy.",
			"Made with classic combinations using fresh-ingredients to make our pizzas delicious. We use traditional toppings including mozzarella cheese, tomato sauce, and a selection of meats, vegetables, and herbs.",
			"NYPD pizza menu is composed of 7 signature pizza and customers can choose to remove or add toppings they want.",
		],
		icon: icon6,
	},
	{
		img: img7,
		title: "NYPD Pasta",
		subtitle: "Authentic New York Style Pasta",
		text: [
			"NYPD Pasta offers recipes inspired by the iconic flavors of New York, featuring hand-crafted pasta dishes that blend tradition and innovation.",
			"Our dishes are made with fresh ingredients to ensure every bite is delicious. We use classic toppings and sauces, including rich tomato sauces, creamy alfredo, and a variety of meats, vegetables, and herbs.",
			"The NYPD Pasta menu includes 7 signature pasta dishes, with the option for customers to customize their meals by adding or removing ingredients to suit their tastes.",
		],
		icon: icon6,
	},
];

const Brands = () => {
	return (
		<>
			<section className="section-gap brands-inner-section">
				{data?.map(({ img, title, subtitle, text, icon, center }, i) => (
					<section
						className="position-relative overflow-hidden section-gap"
						key={i}
					>
						<img
							src={shapes}
							className={`shape ${i % 2 === 0 ? "odd" : ""}`}
							alt=""
						/>
						<div className="container">
							<div
								className={`row g-4 ${i % 2 === 0 ? "flex-row-reverse" : ""
									}`}
							>
								<div className="col-lg-6 text-center ">
									<img
										src={img}
										alt=""
										className={`mw-100 mt-lg-5 ${center ? "pt-lg-5" : ""
											}`}
									/>
								</div>
								<div className="col-lg-6">
									<SectionTitle
										shapeColor
										title={title}
										text={subtitle}
									/>
									<div className="text-contents">
										{text?.map((item, j) => (
											<p key={j}>{item}</p>
										))}
									</div>
									{icon && (
										<img src={icon} className="pt-3 mw-100" alt="" />
									)}
								</div>
							</div>
						</div>
					</section>
				))}
			</section>
			<BookNow />
		</>
	);
};

export default Brands;
