import React from "react";
import BlogCard from "./components/BlogCard";
import Subscribe from "./components/Subscribe";

const Blog = () => {
	return (
		<>
			<Subscribe />
			<BlogCard />
		</>
	);
};

export default Blog;
