import React from "react";
import bg from "../img/page-header.png";
import thumb from "../img/thumb.png";

const PageHeader = () => {
	return (
		<section
			className="page-header"
			style={{
				background: `url(${bg}) no-repeat center center / cover`,
				padding: "33px 0 39px",
			}}
		>
			<div className="container">
				<img src={thumb} alt="" />
			</div>
		</section>
	);
};

export default PageHeader;
