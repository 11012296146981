import React from "react";
import { Link } from "react-router-dom";
import SectionTitle from "../../../../components/SectionTitle";
import img1 from "../img/brands/brand-1.png";
import img2 from "../img/brands/brand-2.png";
import img3 from "../img/brands/brand-3.png";
import img4 from "../img/brands/brand-4.png";
import img5 from "../img/brands/brand-5.png";
import img6 from "../img/brands/brand-6.png";
import img7 from "../img/brands/brand-7.png";


const data = [
	{
		id: "1",
		img: img1,
		title: "Just Chicken Wings",
	},
	{
		id: "1",
		img: img2,
		title: "The Imperial Bamboo",
	},
	{
		id: "1",
		img: img3,
		title: "Burger French Fries",
	},
	{
		id: "1",
		img: img4,
		title: "Crazy Tasty Chicken",
	},
	{
		id: "1",
		img: img5,
		title: "Sub’s Zone",
	},
	{
		id: "1",
		img: img6,
		title: "NYPD Pizza",
	},
	{
		id: "1",
		img: img7,
		title: "NYPD Pasta",
	},
];

const Brands = () => {
	return (
		<section className="section-gap virtual-brands-section">
			<div className="container">
				<SectionTitle
					title="Virtual Brands"
					shapeColor="var(--title)"
					centerAlign
				/>
				<div className="header-text text-center">
					Simple Virtual Kitchen offers restaurant owners a separate
					concept that runs out of their kitchen and we provide them with
					3rd party delivery at our cost. Our virtual brands are an
					effective way for restaurateurs to increase their bottom line by
					optimizing their kitchen space using their existing equipments.
				</div>
				<div className="row gx-1 gy-3">
					{data?.map(({ id, img, title }, i) => (
						<div className="col-lg-4 col-sm-6" key={i}>
							<div className="brands-item">
								<img src={img} alt="" />
								<div className="content">
									<h5>{title}</h5>
									<Link to="/brands" className="cmn-btn">
										<span>LEARN MORE</span>
									</Link>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</section>
	);
};

export default Brands;
