import React from "react";
import SectionTitle from "../../../components/SectionTitle";
import img from "../img/points.png";
const FreeDelivery = () => {
	return (
		<section className="section-gap position-relative">
			<div className="container">
				<div className="row">
					<div className="col-lg-7" style={{ maxWidth: "719px" }}>
						<SectionTitle
							title="Free Delivery with Hey! Jag"
							shapeColor
						/>
						<p>
							Hey! Jag Delivery is a service brought by Simple Virtual
							Kitchen through which restaurant partners are connected to
							our drivers, providing a hassle-free food delivery
							experience for customers at our expense.
						</p>
						<p>
							Customers can either place their orders directly from our
							virtual brand’s website or directly on Hey! Jag mobile app
							to get their food delivered from your restaurant by our
							driver partners.
						</p>
						<h4 style={{ color: "#9D8140", marginTop: "34px" }}>
							Virtual brand partners get $0 delivery and service fees.
						</h4>
					</div>
					<div className="col-lg-5 text-center">
						<img src={img} alt="" className="hand-img" />
					</div>
				</div>
			</div>
		</section>
	);
};

export default FreeDelivery;
