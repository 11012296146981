import React from "react";
import BookNow from "../../components/BookNow";
import About from "./components/about/About";
import Banner from "./components/banner/Banner";
import Brands from "./components/brands/Brands";
import How from "./components/how/How";
import ThirdParty from "./components/third-party/ThirdParty";
import WhyPartner from "./components/why-partner/WhyPartner";

const Home = () => {
	return (
		<>
			<Banner />
			<About />
			<Brands />
			<How />
			<WhyPartner />
			<ThirdParty />
			<BookNow />
		</>
	);
};

export default Home;
