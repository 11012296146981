import React from "react";
import { Link } from "react-router-dom";
import SectionTitle from "../../../components/SectionTitle";

import points from "../img/points.png";
import img from "../img/story.png";
const data = [
	"Created in 2020 Just Chicken Wings was the first virtual kitchen concept invented by Mohamed Kaada during the Covid-19 pandemic.",
	"During this time, many restaurants were out of business and many studies agreed to say that the majority of them will not recover before 2025. Mohamed’s restaurant NYPD Pizza located in Lake Worth Florida, wasn’t an exception and lost 60% of sales. ",
	"To remedy this situation he decided to launch his first virtual brand and new concept Just Chicken Wings, a chicken wings and boneless wings virtual brand based on an only-delivery model.",
	"By adding this second brand to his restaurant, he maximized the profit of his kitchen and staff. No extra charge on utilities, rent or employees. ",
	"It was a huge hit and the total net sales per month reached $20.000, more than his NYPD restaurant by itself. Following this success, Mohamed sold his owned restaurants to focus on his virtual brand concept and decided to partner with restaurant owners in order to help and make profit with them.",
	"In 2021 he decided with his team to launch five more virtual brand concepts adaptable into any restaurant's kitchen equipment and regrouped them under Simple Virtual Kitchen.",
	"SVK is here to support restaurant owners to go through the restaurant industry shift and the digitalization of this sector by providing them with the best turnkey solution as possible.",
];
const Story = () => {
	return (
		<section className="story-section section-gap">
			<img src={points} alt="" className="points" />
			<div className="container">
				<div className="row g-4">
					<div className="col-lg-7">
						<div className="story-content">
							<SectionTitle shapeColor title="Our Story" />
							{data?.map((item, i) => (
								<p key={i}>{item}</p>
							))}
							<div className="pt-3">
								<Link to="/brands" className="cmn-btn">
									<span>ALL BRANDS</span>
								</Link>
							</div>
						</div>
					</div>
					<div className="col-lg-5">
						<div className="h-100 ms-lg-auto story-img-thumb">
							<img src={img} className="story-img" alt="" />
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Story;
