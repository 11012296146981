import React, { useRef } from "react";
import img from "../assets/img/book-now-bg.png";
import SectionTitle from "./SectionTitle";
import emailjs from '@emailjs/browser';
import { toast } from "react-hot-toast";


const BookNow = () => {
	const form = useRef();

	const handleSubmit = (e) => {
		e.preventDefault();
		toast.loading('Sending...', {
			id: 1,
		});

		emailjs.sendForm('service_3rtqe95', 'template_rbls5lj', form.current, process.env.REACT_APP_EMAILJS_PUBLIC_API)
			.then((result) => {
				if (result.status === 200) {
					toast.success("Thank you for reaching out! We've received your message and will get back to you soon", {
						id: 1
					})
					e.target.reset()
				}
			}, (error) => {
				toast.error("Something went wrong! Please try again.", {
					id: 1
				})
			});
	};

	return (
		<section
			className="book-now-section"
			style={{ background: `url(${img}) no-repeat center center / cover` }}
		>
			<div className="container">
				<div className="book-form">
					<SectionTitle
						shapeColor
						title="Book Now"
						className="mb-3"
						centerAlign
					/>
					<div className="text-center mb-4 pb-2">
						Interested in our virtual brands? <br />
						Tell us more about your restaurant and let’s hop on a call.
					</div>
					<form ref={form} onSubmit={handleSubmit}>
						<div className="row g-3">
							<div className="col-sm-12">
								<label htmlFor="b-name" className="form-label">
									Name
								</label>
								<input
									required
									name="Name"
									type="text"
									className="form-control"
									id="b-name"
								/>
							</div>
							<div className="col-sm-6">
								<label htmlFor="b-email" className="form-label">
									Email Address
								</label>
								<input
									required
									name="Email Address"
									type="email"
									className="form-control"
									id="b-email"
								/>
							</div>
							<div className="col-sm-6">
								<label htmlFor="b-phone" className="form-label">
									Phone Number
								</label>
								<input
									required
									name="Phone Number"
									type="text"
									className="form-control"
									id="b-phone"
								/>
							</div>
							<div className="col-sm-12">
								<label htmlFor="message" className="form-label">
									Message
								</label>
								<textarea
									required
									name="Message"
									id="message"
									className="form-control"
								></textarea>
							</div>
							<div className="col-sm-12 mt-4 pt-1">
								<button className="cmn-btn w-100" type="submit">
									<span>SUBMIT</span>
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</section>
	);
};

export default BookNow;
