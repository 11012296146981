import React from "react";
import img from "../img/page-header.png";
const PageHeader = () => {
	return (
		<div
			className="page-header"
			style={{ background: `url(${img}) no-repeat center center / cover` }}
		>
			<div className="container">
				<h1 className="title">Contact Us Today!</h1>
			</div>
		</div>
	);
};

export default PageHeader;
