import React from "react";
import { Link } from "react-router-dom";
import shapes from "../../../assets/img/shapes.png";
import SectionTitle from "../../../components/SectionTitle";
import img from "../img/about.png";

import david from "../img/1.png";
import karima from "../img/2.png";
import mohamed from "../img/3.png";

const AboutSection = () => {
	// const [open, setOpen] = useState(false);
	return (
		<>
			<section className="about-section section-gap overflow-hidden position-relative">
				<img src={shapes} className="shape odd" alt="" />
				<div className="container">
					<div className="row gy-5 align-items-center flex-wrap-reverse">
						<div className="col-lg-6">
							<SectionTitle
								title="About Us"
								subtext="Simple Virtual Kitchen is bringing restaurants to an all-new world."
							/>
							<div className="mt-29" style={{ maxWidth: "580px" }}>
								<p>
									All of our board members came from the restaurant
									industry and understand the amount of time and work
									spent by restaurants to reach a small profit. We
									combined more than 50 years of experience in this
									sector to bring these virtual brand concepts to life
									and help owners increase their margin.
								</p>
								<p>
									Our team partner with restaurant owners to finally
									get what they deserve and allow them to benefit the
									most of their business.
								</p>
							</div>
							<Link
								to="/about"
								className="cmn-btn mt-37"
								data-bs-toggle="modal"
								data-bs-target="#exampleModal"
							>
								<span>LEARN MORE</span>
							</Link>
						</div>
						<div className="col-lg-6 text-lg-end">
							<img
								src={img}
								alt=""
								className="mw-100"
								style={{ borderRadius: "10px" }}
							/>
						</div>
					</div>
				</div>
			</section>
			<TeamModal />
		</>
	);
};
const data = [
	{
		img: mohamed,
		name: "Mohamed Kaada",
		designation: "CEO & Founder",
		info: [
			"+26 years in the restaurants industry",
			"Founder & CEO, Hey! Jag",
			"Founder & CEO, Simple Virtual Kitchen",
			"Founder & CEO, Madalinshik",
			"Founder & CEO, Just Chicken Wings",
			'Founder & CEO, NYPD Pizza',
			'Founder & CEO, Café Prélude',
			'Board Member, French Tech Miami',
		],
		linkedin: "https://www.linkedin.com/in/mohamed-kaada-098215230/",
	},
	{
		img: karima,
		name: "Karima Miloud",
		designation: "CTO  & Culinary Director",
		info: [
			"+22 years in the restaurant industry",
			"Co Founder & CEO, Springfield restaurant franchise in France ",
			"Private Chef in West Palm ",
			"Operating fine dining & casual restaurant",
			"Founder & CEO, La Buvette",
		],
		linkedin: "https://www.linkedin.com/in/karima-miloud-b42068191/",
	},
	{
		img: david,
		name: "Louis Conduche",
		designation: "CMO",
		info: [
			"+7 years in digital marketing",
			"With a specialization in search engine",
			"optimization and digital ads",
			"Louis is able to drive growth and",
			'increase brand awareness through',
			'effective digital marketing strategies'
		],
		linkedin: "https://www.linkedin.com/in/louis-conduch%C3%A9/",
	},
];
export const TeamModal = ({ open, setOpen }) => {
	return (
		<>
			<div className="modal fade team-modal" id="exampleModal">
				<div className="modal-dialog modal-xl">
					<div className="modal-content">
						<div className="modal-header border-0">
							<h5 className="modal-title ms-auto">Meet the Team</h5>
							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						</div>
						<div className="modal-body">
							<div className="row g-4 justify-content-center">
								{data?.map((item, i) => (
									<TeamCard key={i} {...item} />
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

const TeamCard = ({ img, name, designation, info, linkedin }) => {
	return (
		<>
			<div className="col-lg-4 col-sm-6">
				<div className="team-card">
					<img src={img} alt="" className="author-img" />
					<h5 className="name">{name}</h5>
					<div className="designation">{designation}</div>
					<ul>
						{info?.map((item, i) => (
							<li key={i}>{item}</li>
						))}
					</ul>
					<Link to={linkedin} className="cmn-btn" target="_blank">
						<span>Linkedin</span>
					</Link>
				</div>
			</div>
		</>
	);
};

export default AboutSection;
