import React from "react";
import SectionTitle from "../../../../components/SectionTitle";
import { RightShape } from "../../../../IconTheme";
import img from "../img/how-img.png";
const How = () => {
	return (
		<section className="how-section section-gap">
			<div className="right-shape">
				<RightShape />
			</div>
			<div className="left-shape">
				<RightShape />
			</div>
			<div className="container">
				<SectionTitle shapeColor title="How Does It Works" centerAlign />
				<div className="text-center">
					<img src={img} alt="" className="mw-100" />
				</div>
			</div>
		</section>
	);
};

export default How;
