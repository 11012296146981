/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";

const Maps = () => {
	return (
		<>
			<iframe
				src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3562.502735353017!2d-80.09247718459807!3d26.76024017307871!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d8d58c31df9d8d%3A0x737a73b86899a99!2s5601%20Corporate%20Way%2C%20West%20Palm%20Beach%2C%20FL%2033407%2C%20USA!5e0!3m2!1sen!2sbd!4v1677770438487!5m2!1sen!2sbd"
				height="387"
				style={{ width: "100%", display: "block" }}
				frameBorder="0"
			></iframe>
		</>
	);
};

export default Maps;
