import React from "react";

const SectionTitle = ({
	title,
	text,
	shapeColor,
	centerAlign,
	className,
	subtext,
}) => {
	return (
		<>
			<div
				className={`section-title ${
					centerAlign ? "mx-auto text-center" : ""
				} ${className ? className : ""}`}
			>
				{shapeColor && (
					<span
						className={`line ${centerAlign ? "mx-auto" : ""}`}
						style={{
							background: `${shapeColor ? shapeColor : "var(--base)"}`,
						}}
					></span>
				)}
				{title && <h2 className="title">{title}</h2>}
				{text && <p className="text">{text}</p>}
				{subtext && <p>{subtext}</p>}
			</div>
		</>
	);
};

export default SectionTitle;
