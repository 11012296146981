import React from "react";
import { Link } from "react-router-dom";
import SectionTitle from "../../../../components/SectionTitle";
import img from "../img/third-party.png";
const ThirdParty = () => {
	return (
		<>
			<section className="third-party-section section-gap overflow-hidden">
				<div className="container">
					<div className="row gy-5 gx-xxl-5 flex-wrap-reverse">
						<div className="col-lg-6">
							<div dir="rtl">
								<img src={img} alt="" className="max-xl-100" />
							</div>
						</div>
						<div className="col-lg-6">
							<SectionTitle
								shapeColor
								title="3rd Party Delivery"
								text="Orders are delivered to customers by our drivers for free!"
							/>
							<div className="mt-29">
								<p>
									Restaurant partners benefit from our free delivery as
									we use our own platform to complete deliveries at our
									expense:
									<strong> No service fees or hidden fees</strong>.
								</p>
								<p>
									Expend your reach and increase your revenue with our
									driver partners and make the most of your restaurant
									by adding one of our virtual brand.
								</p>
								<p>
									SVK is committed to support restaurants going through
									the restaurant industry digitalisation and the
									evolution of customer’s consumption habits in order
									to remain competitive as the demand for delivery of
									meals continues to rise.
								</p>
							</div>
							<Link to="/delivery" className="cmn-btn mt-37">
								<span>SEE HOW</span>
							</Link>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default ThirdParty;
