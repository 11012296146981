import React from "react";
import SectionTitle from "../../../components/SectionTitle";
import img1 from "../img/how1.png";
import img2 from "../img/how2.png";
import img3 from "../img/how3.png";
const data = [
	{
		img: img1,
		title: "Your restaurant is connected to our virtual brand on our mobile app.",
	},
	{
		img: img2,
		title: "Customers place their orders on our virtual brand and you receive it on your hand.",
	},
	{
		img: img3,
		dots: ["Accept the order", "Prepare the food", "Driver pick it up"],
	},
];

const HowToDeliver = () => {
	return (
		<section className="how-to-deliver-section">
			<div className="container">
				<SectionTitle
					title="How Hey!Jag Delivery works for virtual brand partners"
					shapeColor
				/>
			</div>
			<div className="bg--gradient section-gap">
				<div className="container">
					<div className="row g-xl-5 g-4 justify-content-center">
						{data?.map(({ img, title, dots }, i) => (
							<div className="col-sm-6 col-md-4" key={i}>
								<div className="how-card">
									<div className="serial text--base">{i + 1}.</div>
									<div className="img">
										<img src={img} alt="" />
									</div>
									{title && <h6>{title}</h6>}
									{dots && (
										<ul>
											{dots.map((item, j) => (
												<li key={j}>{item}</li>
											))}
										</ul>
									)}
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</section>
	);
};

export default HowToDeliver;
