import React from "react";
import {
	FairAndProfitable,
	Friendly,
	Partner,
	TurnKey,
} from "../../../IconTheme";
const data = [
	{
		icon: <Partner />,
		text: "+100 Restaurant Partners",
	},
	{
		icon: <Friendly />,
		text: "Friendly & Professional",
	},
	{
		icon: <TurnKey />,
		text: "Turnkey Solution",
	},
	{
		icon: <FairAndProfitable />,
		text: "Fair & Profitable",
	},
];
const AboutFeature = () => {
	return (
		<section className="about-feature-section">
			<div className="container">
				<div className="row g-3 g-xl-4">
					{data?.map(({ icon, text }, i) => (
						<div className="col-6 col-md-3" key={i}>
							<div className="feature-card">
								<div className="icon">{icon}</div>
								<h6 className="title">{text}</h6>
							</div>
						</div>
					))}
				</div>
			</div>
		</section>
	);
};

export default AboutFeature;
