import emailjs from "@emailjs/browser";
import React, { useRef } from "react";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import bg from "../img/banner-bg.png";

const Banner = () => {
	const form = useRef();

	const handleSubmit = (e) => {
		e.preventDefault();

		e.preventDefault();
		toast.loading("Sending...", {
			id: 1,
		});

		emailjs
			.sendForm(
				"service_3rtqe95",
				"template_4jjxhby",
				form.current,
				process.env.REACT_APP_EMAILJS_PUBLIC_API
			)
			.then(
				(result) => {
					if (result.status === 200) {
						toast.success(
							"Thank you for reaching out! We've received your message and will get back to you soon",
							{
								id: 1,
							}
						);
						e.target.reset();
					}
				},
				(error) => {
					toast.error("Something went wrong! Please try again.", {
						id: 1,
					});
				}
			);
	};
	return (
		<>
			<section
				className="banner-section"
				style={{ background: `url(${bg}) no-repeat center center / cover` }}
			>
				<div className="container">
					<div className="banner-wrapper">
						<div className="banner-content">
							<span className="line"></span>
							<h1 className="title">
								Add A Virtual Brand To Your Restaurant Kitchen
							</h1>
							<p>
								Increase your restaurant profit without increasing your
								costs.
							</p>
							<Link to="/contact" className="cmn-btn">
								<span>GET IN TOUCH</span>
							</Link>
						</div>
						<div className="banner-form bg--title">
							<div className="text-center">
								<h5 className="text--base">We want to hear from you</h5>
								<p>
									Send us your details and we’ll get back to you to
									schedule a time to talk.
								</p>
							</div>
							<form ref={form} onSubmit={handleSubmit}>
								<div className="form-group">
									<label className="form-label" htmlFor="name">
										Name
									</label>
									<input
										required
										name="Name"
										className="form-control-2"
										type="text"
										id="name"
									/>
								</div>
								<div className="form-group">
									<label className="form-label" htmlFor="email">
										Email
									</label>
									<input
										required
										name="Email"
										className="form-control-2"
										type="text"
										id="email"
									/>
								</div>
								<div className="form-group">
									<label className="form-label" htmlFor="phone">
										Phone
									</label>
									<input
										required
										name="Phone"
										className="form-control-2"
										type="text"
										id="phone"
									/>
								</div>
								<div className="text-center pt-3">
									<button type="submit" className="cmn-btn">
										<span>send</span>
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Banner;
