import React from "react";
import SectionTitle from "../../../components/SectionTitle";
import img from "../img/subscribe-bg.png";
const Subscribe = () => {
	const handleSubmit = (e) => {
		e.preventDefault();
	};
	return (
		<>
			<div
				className="subscribe-section"
				style={{
					background: `url(${img}) no-repeat center center / cover`,
				}}
			>
				<div className="container">
					<div className="subscribe-content">
						<div className="content">
							<SectionTitle
								centerAlign
								shapeColor
								title="Follow us"
								className="mb-3"
							/>
							<p className="text-center mb-4 pb-2">
								Are you interested in our activities? <br /> Subscribe
								to our newsletter to not miss anything!
							</p>
							<form onSubmit={handleSubmit}>
								<label className="form-label">Email Address</label>
								<input
									type="text"
									className="form-control mb-4 mb-md-5"
								/>
								<button
									className="cmn-btn w-100 text-uppercase"
									type="submit"
								>
									<span>Subscribe</span>
								</button>
							</form>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Subscribe;
