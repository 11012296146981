import React from "react";
import { Accordion } from "react-bootstrap";
import SectionTitle from "../../../components/SectionTitle";
import point from "../img/faq-1.png";
const data = [
	{
		title: "What is a virtual kitchen company?",
		text: [
			"A virtual kitchen company partners with restaurants to launch an additional food brand known as virtual brand, using their existing kitchen, staff and rent based on a delivery-only model. It’s a cost-effective solution for restaurant owners to expand their reach and improve operational efficiency in order to increase their net profit.",
		],
	},
	{
		title: "What is the difference between a virtual kitchen and a ghost kitchen?",
		text: [
			"Virtual kitchen and ghost kitchen concepts are often mistaken and the food industry is still struggling to agree on the differences between these concepts.",
			"In simple terms, virtual kitchen companies implement food brands based on a delivery-only model in a restaurant’s kitchens in order to help restaurant owners make more profit from their business. Ghost kitchen companies aren’t attached to a regular restaurant, instead they operate out of a space that is rented and implements multiple food brands based on a delivery-only model.",
		],
	},
	{
		title: "How does Simple Virtual Kitchen provide third-party delivery?",
		text: [
			"Simple Virtual Kitchen provides its own driver partners that we remunerate on our end.",
		],
	},
	{
		title: "How does it cost me to partner with Simple Virtual Kitchen?",
		text: [
			"It cost you 0$ to start partnering with us.",
			"You start paying us after the 1st month of the partnership on sales you are making through our virtual brand.",
		],
	},
	{
		title: "Can I have multiple virtual brands running through my kitchen?",
		text: [
			"Yes, you can have up to 3 virtual brands running from your restaurant depending on your kitchen space and orders fulfillment capacity.",
			"Note that you can start with only one virtual brand before",
		],
	},
	{
		title: "What percentage of sales am I keeping?",
		text: [
			"From 100% of the total sales: your restaurant keeps 70% and Simple Virtual Kitchen 30%.",
			"Restaurants use on average 30% of their total sales to restock in food and packaging to sell through the virtual brand and keep 40% net profit for themselves.",
		],
	},
	{
		title: "When do I get paid?",
		text: [
			"You’ll get paid at each delivery completed through our virtual brand.",
		],
	},
	{
		title: "Do other restaurant partners having the same virtual brand as my restaurant can receive orders instead of mine?",
		text: [
			"According to our competition policy, a distance of at least 4 miles between each restaurant partner is respected in order to reserve an exclusive activity area for each of them.",
		],
	},
	{
		title: "What is a virtual kitchen company?",
		text: [
			"Depending on the virtual brand, it takes on average 2 weeks before you start taking orders.",
		],
	},
	{
		title: "Can I end the partnership whenever I want?",
		text: [
			"Yes, you need to respect a 14 days prior notice in order to stop the partnership.",
		],
	},
];

const Faqs = () => {
	return (
		<section className="section-gap faqs-section pt-0 pt-lg-4 position-relative overflow-hidden">
			<img
				src={point}
				alt=""
				className="point-1"
				style={{
					position: "absolute",
					top: "100px",
					opacity: ".7",
					right: "0",
				}}
			/>
			<img
				src={point}
				alt=""
				className="point-2"
				style={{
					position: "absolute",
					bottom: "100px",
					opacity: ".7",
					left: "0",
				}}
			/>
			<div className="container">
				<SectionTitle
					shapeColor
					title="Frequently Asked Questions"
					centerAlign
				/>
				<div className="__accordion">
					<Accordion>
						{data?.map(({ title, text }, i) => (
							<Accordion.Item eventKey={i} key={i}>
								<Accordion.Header>
									{i + 1}.{title}
								</Accordion.Header>
								<Accordion.Body>
									{text?.map((item, j) => (
										<p key={j}>{item}</p>
									))}
								</Accordion.Body>
							</Accordion.Item>
						))}
					</Accordion>
				</div>
			</div>
		</section>
	);
};

export default Faqs;
