import React from "react";
import { Link } from "react-router-dom";
import SectionTitle from "../../../../components/SectionTitle";
import { Check } from "../../../../IconTheme";
import bg_img from "../img/why-partner-bg.png";
import img from "../img/why-partner.png";

const data = [
	{
		title: "Easy to get started",
	},
	{
		title: "Marketing",
	},
	{
		title: "No investment to start",
	},
	{
		title: "Promotional support",
	},
	{
		title: "Second revenue stream",
	},
	{
		title: "Tablet & Middleware",
	},
	{
		title: "3rd party delivery provided ",
	},
	{
		title: "Instant payment",
	},
	{
		title: "Use existing kitchen & equipment",
	},
	{
		title: "Training programs",
	},
	{
		title: "Focus on preparing orders",
	},
	{
		title: "24/7 customer support",
	},
];

const WhyPartner = () => {
	return (
		<section
			className="why-partner-section section-gap"
			style={{
				background: `url(${bg_img}) no-repeat center center / cover`,
			}}
		>
			<div className="container">
				<div className="row g-4 gy-5 flex-wrap-reverse align-items-center">
					<div className="col-lg-6">
						<div className="partner-content h-100">
							<SectionTitle
								shapeColor
								title="Why Partner With Us?"
								text="No more financial stress, you take care of the food, we take care of the rest."
								className="mb-4"
							/>
							<div className="text">
								Going virtual is the key to increase your profit margin
								and is the future of this industry. Each concept brings
								on average from $5000 to $15.000 per month to our
								partner. <br /> SVK virtual brands are designed to fit
								any restaurant’s kitchen based on their equipments and
								can be up and running taking order in 1 week.
							</div>
							{data && (
								<ul className="checks-list">
									{data.map((item, i) => (
										<li key={i}>
											<Check /> {item.title}
										</li>
									))}
								</ul>
							)}
							<div className="mt-37">
								<Link to="/brands" className="cmn-btn">
									<span>ALL BRANDS</span>
								</Link>
							</div>
						</div>
					</div>
					<div className="col-lg-6">
						<div className="ps-xl-2">
							<img src={img} alt="" className="w-100" />
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default WhyPartner;
