import React from "react";
import BookNow from "../../../components/BookNow";
import ContactSection from "./components/ContactSection";
import EventsMapPage from "./components/Maps";
import PageHeader from "./components/PageHeader";

const Contact = () => {
	return (
		<>
			<PageHeader />
			<ContactSection />
			<EventsMapPage />
			<BookNow />
		</>
	);
};

export default Contact;
