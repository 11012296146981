import React from "react";
import { Link } from "react-router-dom";
import SectionTitle from "../../../../components/SectionTitle";
import img from "../img/about.png";
import bg from "../img/virtual-kitchen-bg.png";
const About = () => {
	return (
		<section
			className="about-section section-gap"
			style={{ background: `url(${bg}) no-repeat center center / cover` }}
		>
			<div className="container">
				<div className="row gy-5 flex-wrap-reverse">
					<div className="col-lg-6">
						<SectionTitle
							title="Simple Virtual Kitchen"
							text="A turnkey solution to add revenue  based on delivery-only model."
						/>
						<div
							className="mt-29"
							style={{ maxWidth: "578px", textAlign: "justify" }}
						>
							Simple Virtual Kitchen provides restaurant partners with
							virtual brands to increase their sales without increasing
							operational costs. Using your existing kitchen, rent and
							staff we implement one of our virtual brand and optimize
							your kitchen space. Sales you are making through our
							virtual brands help you cover your expense and generate a
							net profit by at least 40% per month.
						</div>
						<Link to="/about" className="cmn-btn mt-37">
							<span>LEARN MORE</span>
						</Link>
					</div>
					<div className="col-lg-6 text-lg-end">
						<img src={img} alt="" className="mw-100" />
					</div>
				</div>
			</div>
		</section>
	);
};

export default About;
