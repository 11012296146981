import React from "react";
import BookNow from "../../components/BookNow";
import AboutFeature from "./components/AboutFeature";
import AboutSection from "./components/AboutSection";
import Faqs from "./components/Faqs";
import Story from "./components/Story";

const About = () => {
	return (
		<>
			<AboutSection />
			<AboutFeature />
			<Story />
			<Faqs />
			<BookNow />
		</>
	);
};

export default About;
